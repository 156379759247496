import React, { Component } from 'react';


class Playbuzz extends Component {

    constructor(props){
        super(props)       
    }
	componentDidMount() {
        
        (function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0];if(d.getElementById(id))return;js=d.createElement(s);js.id=id;js.src='https://embed.playbuzz.com/sdk.js';fjs.parentNode.insertBefore(js,fjs);}(document,'script','playbuzz-sdk'));     
        
	}
    
	render() {
        let campaign = this.props.campaign
        if(campaign=="cats"){
            return <div className="playbuzz" data-id="2f9dcb0a-ae40-46e9-b3b3-e731133b9a19" data-comments="false"></div>
        }
        else if(campaign=="luck"){
            return <div class="playbuzz" data-id="2145e657-01ba-48ee-b865-bdc6de130dff" data-comments="false"></div>
        }
        else{
            return null
        }
            
	}
}

export default Playbuzz;